<template>
  <div class="sideNav flex font-body">
    <div class="w-64 bg-slate-50 border-r border-slate-200 divide-y divide-solid hover:divide-dashed">

      <div class="px-4 py-2">

        <router-link to="/app">
          <h1>Minerva</h1>
        </router-link>
      </div>

      <div class="mb-6 pt-4">
        <h3 class="mx-6 mb-2 text-xs text-gray-400 uppercase tracking-widest">Road Section</h3>
        <router-link :to="{name: 'Table'}" class="block text-main px-10 py-2 hover:text-red-400">Table</router-link>
        <router-link :to="{name: 'Map'}" class="block text-main px-10 py-2 hover:text-red-400">Map</router-link>
      </div>
      <div
          v-if="user.isAdmin"
          class="mb-10 pt-4"
      >
        <h3 class="mx-6 mb-2 text-xs text-gray-400 uppercase tracking-widest">Management</h3>
        <router-link :to="{name: 'Upload'}" class="block text-main px-10 py-2 hover:text-red-400">Upload</router-link>
        <router-link
            :to="{name: 'UserPermissions'}"
            class="block text-main px-10 py-2 hover:text-red-400"
        >
          Users
        </router-link>
      </div>
      <div class="mb-10 pt-4">
        <h3 class="mx-6 mb-2 text-xs text-gray-400 uppercase tracking-widest">Session</h3>
        <a
            class="block text-main px-10 py-2 hover:text-red-400"
            @click="onLogout"
        >Logout</a>
      </div>
    </div>
  </div>
</template>

<script>
import {LOGOUT} from "@/store/operations";
import {useStore} from "vuex";
import {useRouter} from 'vue-router'
import {computed} from "vue";

export default {
  name: "SideNav",
  setup() {
    const store = useStore()
    const user = computed(() => store.state.user)
    const router = useRouter()

    const onLogout = () => store.dispatch(LOGOUT, store.state.user?.email).finally(() => router.push({path: '/'}))

    return {
      onLogout,
      user,
    }
  }
}
</script>

<style scoped>

</style>
