<template>
  <div class="appView">
    <div class="flex min-h-screen text-slate-700">
      <SideNav></SideNav>
      <div class="w-full">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
export default {
  name: "AppView",
  components: {
    SideNav,
  },
};
</script>
